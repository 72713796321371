<template>
  <div class="resource-box">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="资源管理" />
    <div class="resource-main">
      <nav-menu-tree
        :lazy="false"
        :treeData="treeData"
        :tree-props="menuTreeOptions"
        :currentNodeKey="menuActiveId"
        :expandedKeys="expandedKeys"
        :btnList="btnList"
        :draggable="true"
        @drop="dropHandle"
        @menu-item-click="menuItemClick"
        @delete-click="deleteTreeHandle"
        @add-click="addTreeHandle"
      ></nav-menu-tree>
      <div class="page-right-box">
        <pt-resource-tool @add="addHandle" />
        <pt-resource-detail
          :resourceId="menuActiveId"
          @save-success="saveDetailHandle"
          @delete="deleteHandle"
        />
      </div>
    </div>
    <pt-resource-dialog
      v-if="showResourceDialog"
      ref="resourceDialogRef"
      :resourceId="addItemId"
      @save-success="saveDetailHandle"
      @close="closeHandle"
    ></pt-resource-dialog>
    <!-- 删除提示框 -->
    <ml-dialog ref="deleteDialogRef" :content="deleteData.content" @click-submit="submitDelete" />
  </div>
</template>

<script>
import { onMounted, reactive, ref, nextTick } from 'vue'
import { useStore } from 'vuex'
import navMenuTree from './components/navMenuTree.vue'
import PtResourceTool from './components/PtResourceTool.vue'
import PtResourceDetail from './components/PtResourceDetail.vue'
import PtResourceDialog from './components/PtResourceDialog.vue'
import { findTreeParentNodes } from '@/utils/index'
export default {
  name: 'resourcePage',
  components: {
    navMenuTree,
    PtResourceTool,
    PtResourceDetail,
    PtResourceDialog
  },
  setup() {
    const { commit, dispatch, getters } = useStore()
    const treeData = ref([])
    const menuActiveId = ref('')
    const currentClickItem = ref({})
    const menuTreeOptions = reactive({
      label: 'title'
    })
    const btnList = ref([
      { name: '新增', code: 'add' },
      { name: '删除', code: 'delete' }
    ])
    const expandedKeys = ref([])
    const getResourceData = () => {
      dispatch('fetchGetModuleResourceAllTreeList').then(data => {
        if (data.code === 200) {
          treeData.value = data.data
          if (!menuActiveId.value) {
            getMenuTreeFirstLeave(treeData.value)
          } else {
            let parents = findTreeParentNodes(treeData.value, currentClickItem.value)
            expandedKeys.value = parents.map(d => d.id)
            let menuActiveCopyId = menuActiveId.value
            menuActiveId.value = ''
            setTimeout(() => {
              menuActiveId.value = menuActiveCopyId
            }, 100)
          }
        }
      })
    }
    const getMenuTreeFirstLeave = data => {
      if (data.length > 0) {
        expandedKeys.value.push(data[0].id)
        if (data[0].childsNode) {
          this.getMenuTreeFirstLeave(data[0].childsNode)
        } else {
          menuActiveId.value = data[0].id
          currentClickItem.value = data[0]
        }
      }
    }
    const menuItemClick = node => {
      if (node.id === menuActiveId.value) return
      menuActiveId.value = node.id
      currentClickItem.value = node
    }
    const dropHandle = data => {}
    const saveDetailHandle = () => {
      getResourceData()
    }
    const addItemId = ref('')
    const resourceDialogRef = ref()
    const showResourceDialog = ref(false)
    const addHandle = async () => {
      addItemId.value = menuActiveId.value
      showResourceDialog.value = true
      await nextTick()
      resourceDialogRef.value.dialogRef.showDialog = true
    }
    const addTreeHandle = async row => {
      addItemId.value = row.data.id
      showResourceDialog.value = true
      await nextTick()
      resourceDialogRef.value.dialogRef.showDialog = true
    }
    const closeHandle = () => {
      showResourceDialog.value = false
    }
    // 删除
    const deleteDialogRef = ref()
    const deleteData = reactive({
      content: '确定删除该资源？',
      id: ''
    })
    const deleteHandle = () => {
      deleteData.id = menuActiveId.value
      deleteDialogRef.value.showDialog = true
    }
    const deleteTreeHandle = row => {
      deleteData.id = row.data.id
      deleteDialogRef.value.showDialog = true
    }

    const submitDelete = () => {
      const { id } = deleteData
      dispatch('fetchRemoveSysModule', id).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          if (id === menuActiveId.value) {
            menuActiveId.value = ''
          }
          deleteDialogRef.value.showDialog = false
          getResourceData()
        }
      })
    }

    onMounted(async () => {
      getResourceData()
    })
    return {
      treeData,
      menuTreeOptions,
      expandedKeys,
      getResourceData,
      btnList,
      dropHandle,
      menuItemClick,
      menuActiveId,
      saveDetailHandle,
      addItemId,
      resourceDialogRef,
      addHandle,
      showResourceDialog,
      closeHandle,
      deleteDialogRef,
      deleteHandle,
      deleteData,
      submitDelete,
      deleteTreeHandle,
      addTreeHandle
    }
  }
}
</script>
<style lang="scss" scoped>
.resource-box {
  height: 100%;
}
.resource-main {
  height: calc(100% - 59px);
  box-sizing: border-box;
  display: flex;
}
/deep/ .el-tree-node__content {
  height: 40px;
}

.page-right-box {
  width: calc(100% - 316px);
  height: 100%;
  background: #fff;
  box-sizing: border-box;
}
</style>