<template>
  <div class="resource-detail-box">
    <div class="detail-box">
      <pt-resource-form
        ref="formRef"
        :resourceId="resourceId"
        :operType="operType"
        :treeData="treeData"
        type="dialog"
      ></pt-resource-form>
    </div>
    <div class="detail-bottom">
      <el-button type="primary" size="small" @click="saveHandle" :loading="btnLoading"
        >保存</el-button
      >
      <el-button type="danger" size="small" @click="deleteHandle">删除</el-button>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import PtResourceForm from './PtResourceForm.vue'
export default {
  components: {
    PtResourceForm
  },
  props: {
    resourceId: {
      type: String,
      default: ''
    },
    treeData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props, ctx) {
    const { commit, dispatch, getters } = useStore()
    const operType = ref('edit')
    const btnLoading = ref(false)
    const formRef = ref()
    const deleteHandle = () => {
      ctx.emit('delete')
    }
    const saveHandle = () => {
      formRef.value.validateHandle().then(res => {
        btnLoading.value = true
        dispatch('fetchUpdateSysModule', res.data)
          .then(response => {
            btnLoading.value = false
            if (response.code === 200) {
              commit('setError', {
                status: true,
                title: response.message,
                message: '保存成功...',
                type: 'success'
              })
              ctx.emit('save-success', res.data)
            }
          })
          .catch(() => {
            btnLoading.value = false
          })
      })
    }
    return {
      operType,
      deleteHandle,
      saveHandle,
      btnLoading,
      formRef
    }
  }
}
</script>
<style lang="scss" scoped>
.resource-detail-box {
  height: calc(100% - 82px);
}
.detail-box {
  height: calc(100% - 64px);
  overflow: auto;
  padding: 16px 24px 8px;
  box-sizing: border-box;
}
.detail-bottom {
  height: 64px;
  display: flex;
  align-items: center;
  border-top: 1px solid #f2f5f7;
  padding: 0 16px;
}
</style>