<template>
  <div class="resource-tool-box">
    <el-button type="primary" @click="addHandle" size="small" icon="el-icon-plus"
      >新增资源</el-button
    >
  </div>
</template>
<script>
export default {
  setup(props, ctx) {
    const addHandle = () => {
      ctx.emit('add')
    }
    return {
      addHandle
    }
  }
}
</script>
<style lang="scss" scoped>
.resource-tool-box {
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid #f2f5f7;
}
.icon-unbind {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
</style>