<template>
  <div class="pt-nav-menu-tree-box">
    <div class="nav-title" v-if="title">{{ title }}</div>
    <div class="nav-search-box">
      <el-input
        placeholder="请输入内容"
        v-model="keyWord"
        class="input-with-select"
        size="small"
        @keyup.enter.native="searchHandle"
      >
        <template #append>
          <el-button icon="el-icon-search" @click="searchHandle"></el-button>
        </template>
      </el-input>
    </div>
    <div class="tree-box">
      <!-- :load="loadNode" -->
      <el-tree
        ref="treeRef"
        v-if="treeShow"
        :node-key="nodeKey"
        :props="treeProps"
        :lazy="lazy"
        :data="treeData"
        :highlight-current="true"
        :default-expanded-keys="defaultExpandedKeys"
        :current-node-key="currentNodeKey"
        :filter-node-method="filterNode"
        :expand-on-click-node="false"
        :draggable="draggable"
        :allow-drag="allowDrag"
        :allow-drop="allowDrop"
        @node-click="handleNodeClick"
        @node-drag-start="handleNodeDragStart"
        @node-drop="handleDrop"
      >
        <template #default="{ node, data }">
          <span class="custom-tree-node">
            <span>{{ node.label }}</span>

            <template v-if="btnList.length > 0">
              <el-dropdown :teleported="false" @command="$event => handleCommand($event, node)">
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu class="dropdown-des-box">
                    <el-dropdown-item v-for="item in btnList" :key="item.code" :command="item.code">
                      <div class="drop-item">
                        <img
                          :src="require('@/assets/image/icon/icon-' + item.code + '.svg')"
                          class="drop-item-img drop-item-img-default"
                          alt=""
                          srcset=""
                        />
                        <img
                          :src="require('@/assets/image/icon/icon-' + item.code + '-active.svg')"
                          class="drop-item-img drop-item-img-active"
                          alt=""
                          srcset=""
                        />
                        <span>{{ item.name }}</span>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </span>
        </template>
      </el-tree>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    treeData: {
      type: Array,
      default: function () {
        return []
      }
    },
    lazy: {
      type: Boolean,
      default: true
    },
    url: {
      type: String,
      default: ''
    },
    autoParam: {
      type: Array,
      default: function () {
        return []
      }
    },
    otherParam: {
      type: Object,
      default: function () {
        return {}
      }
    },
    listKey: {
      type: String,
      default: ''
    },
    nodeKey: {
      type: String,
      default: 'id'
    },
    treeProps: {
      type: Object,
      default: function () {
        return {}
      }
    },
    expandedKeys: {
      type: Array,
      default: function () {
        return []
      }
    },
    currentNodeKey: {
      type: String,
      default: ''
    },
    isShowIcon: {
      type: Boolean,
      default: false
    },
    btnList: {
      type: Array,
      default: function () {
        return []
      }
    },
    firstNode: {
      type: Object,
      default: function () {
        return {}
      }
    },
    draggable: {
      type: Boolean,
      default: false
    },
    //显示下拉菜单
    contextMenu: {
      type: Object,
      default: function () {
        return {
          visible: false
        }
      }
    },
    showBtn: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const keyWord = ref('')
    const treeShow = ref(true)
    const treeRef = ref()
    const searchHandle = () => {
      treeRef.value.filter(keyWord.value)
    }
    const filterNode = (value, data) => {
      if (!value) return true
      return data[props.treeProps.label].indexOf(value) !== -1
    }

    const defaultExpandedKeys = ref([])
    watch(
      () => props.expandedKeys,
      newValue => {
        defaultExpandedKeys.value = newValue
      }
    )
    watch(
      () => props.currentNodeKey,
      newValue => {
        console.log(999999, newValue)
        setTimeout(() => {
          if (newValue) {
            treeRef.value.setCurrentKey(newValue)
          } else {
            treeRef.value.setCurrentKey(null)
          }
        }, 100)
      }
    )
    const allowDrag = draggingNode => {
      return true
    }
    const allowDrop = (draggingNode, dropNode, type) => {
      if (draggingNode.level === dropNode.level && type !== 'inner') {
        let currentNode = draggingNode.data
        let targetNode = dropNode.data
        if (currentNode.pid === targetNode.pid) {
          return true
        }
      }
    }
    const handleNodeClick = data => {
      ctx.emit('menu-item-click', data)
    }
    const handleNodeDragStart = (draggingNode, event) => {
      const element = event.target
    }
    const handleDrop = (draggingNode, dropNode, dropType, event) => {
      console.log(9999, draggingNode, dropNode, dropType, event)
      let currentNode = draggingNode.data
      let lists = []
      let sortParams = []
      lists = iterationHandle(this.treeData, currentNode)
      sortParams = lists.map(item => {
        return item.id
      })
      ctx.emit('drop', sortParams)
    }
    const iterationHandle = (treeData, node) => {
      const targetItem = treeData.find(d => d.id === node.id)
      if (targetItem) {
        return treeData
      } else {
        for (let item of treeData) {
          if (item.chi && item.children.length > 0) {
            const foundInChild = iterationHandle(item.children, node)
            if (foundInChild) {
              return foundInChild
            }
          }
        }
      }
    }
    const handleCommand = (command, node) => {
      switch (command) {
        case 'delete':
          ctx.emit('delete-click', node)
          break
        case 'add':
          ctx.emit('add-click', node)
          break
        default:
          break
      }
    }
    return {
      searchHandle,
      keyWord,
      treeShow,
      filterNode,
      treeRef,
      defaultExpandedKeys,
      allowDrag,
      allowDrop,
      handleNodeClick,
      handleNodeDragStart,
      handleDrop,
      handleCommand
    }
  }
}
</script>
<style lang="scss" scoped>
.pt-nav-menu-tree-box {
  width: 300px;
  height: 100%;
  margin-right: 16px;
  background: #fff;
  flex-shrink: 0;
}
.nav-title {
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  box-sizing: border-box;
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

.nav-search-box {
  padding: 16px 16px 0;
  margin-bottom: 12px;
}

.tree-box {
  height: calc(100% - 92px);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 2px 16px;
}
.custom-tree-node {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 14px;
}

.dropdown-des-box {
  padding: 10px 0;
}

.dropdown-des-box .el-dropdown-menu__item {
  padding: 0 20px;
}

.drop-item-img {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  vertical-align: middle;
}

.drop-item-img-active {
  display: none;
}

.drop-item-img-default {
  display: inline-block;
}

.drop-item:hover .drop-item-img-default {
  display: none;
}

.drop-item:hover .drop-item-img-active {
  display: inline-block;
}
.drop-item:hover span {
  color: #0d70f2;
}
.el-icon-more {
  transform: rotate(90deg);
  color: #0d70f2;
}

.el-dropdown-link {
  // display: none;
  margin-right: 8px;
  width: 18px;
  height: 18px;
  background: #edf2f8;
  border-radius: 4px;
  cursor: pointer;
  line-height: 18px;
  text-align: center;
}
</style>