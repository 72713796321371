<template>
  <ml-dialog
    width="1000px"
    ref="dialogRef"
    title="新增资源"
    @click-submit="saveHandle"
    @click-close="closeHandle"
  >
    <template #body>
      <div class="dialog-box">
        <pt-resource-form
          ref="formRef"
          :resourceId="resourceId"
          :operType="operType"
          type="dialog"
        ></pt-resource-form>
      </div>
    </template>
  </ml-dialog>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import PtResourceForm from './PtResourceForm.vue'
export default {
  name: 'PtResourceDialog',
  components: {
    PtResourceForm
  },
  props: {
    resourceId: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const { commit, dispatch } = useStore()
    const dialogRef = ref()
    const operType = ref('add')
    const btnLoading = ref(false)
    const formRef = ref()
    const deleteHandle = () => {
      ctx.emit('delete')
    }
    const saveHandle = () => {
      formRef.value.validateHandle().then(res => {
        btnLoading.value = true
        dispatch('fetchAddSysModule', res.data)
          .then(response => {
            btnLoading.value = false
            if (response.code === 200) {
              commit('setError', {
                status: true,
                title: response.message,
                message: '保存成功...',
                type: 'success'
              })
              ctx.emit('save-success', res.data)
              closeHandle()
            }
          })
          .catch(() => {
            btnLoading.value = false
          })
      })
    }
    const closeHandle = () => {
      dialogRef.value.showDialog = false
      ctx.emit('close')
    }
    return {
      operType,
      deleteHandle,
      saveHandle,
      btnLoading,
      formRef,
      closeHandle,
      dialogRef
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box {
  padding-top: 15px;
  padding-bottom: 8px;
  width: 100%;
}
</style>
