<template>
  <el-form :model="itemData" ref="formRef" :rules="userFormRules" labelWidth="100px">
    <div class="form-box" :class="{ 'form-box-dialog': type === 'dialog' }">
      <div class="form-item-box">
        <el-form-item label="资源名称:" prop="moduleName">
          <el-input
            v-model="itemData.moduleName"
            maxlength="100"
            placeholder="请输入资源名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="资源代码:" prop="moduleCode">
          <el-input
            v-model="itemData.moduleCode"
            maxlength="40"
            placeholder="请输入资源代码"
          ></el-input>
        </el-form-item>
      </div>
      <div class="form-item-box">
        <el-form-item label="资源类型:" prop="moduleType" placeholder="请选择资源类型">
          <el-select v-model="itemData.moduleType">
            <el-option
              v-for="item in moduleTypeOptions"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="父资源:" prop="parentId">
          <el-cascader
            v-model="itemData.parentId"
            style="width: 100%"
            :options="resourceOptions"
            :props="cascaderProps"
            :show-all-levels="false"
            placeholder="请选择父资源"
            clearable
          />
        </el-form-item>
      </div>
    </div>
    <div class="form-box" :class="{ 'form-box-dialog': type === 'dialog' }">
      <div class="form-item-box">
        <el-form-item label="资源路由:" prop="moduleUrl">
          <el-input
            v-model="itemData.moduleUrl"
            maxlength="100"
            placeholder="请输入资源路由"
          ></el-input>
        </el-form-item>

        <el-form-item label="排序:" prop="rank">
          <el-input-number
            v-model="itemData.rank"
            :min="0"
            :precision="0"
            :step="1"
            :max="9999"
            label="顺序"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
      </div>
      <div class="form-item-box">
        <el-form-item label="是否显示:" prop="showStatus">
          <el-radio-group v-model="itemData.showStatus">
            <el-radio v-for="item in radioShowOptions" :key="item.value" :label="item.value">{{
              item.name
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否外链:" prop="iframeFlag">
          <el-radio-group v-model="itemData.iframeFlag">
            <el-radio v-for="item in radioOptions" :key="item.value" :label="item.value">{{
              item.name
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </div>
    <div class="form-box" :class="{ 'form-box-dialog': type === 'dialog' }">
      <div class="form-item-box">
        <el-form-item label="外链方式:" prop="iframeOpenType">
          <el-radio-group v-model="itemData.iframeOpenType">
            <el-radio label="0">内嵌</el-radio>
            <el-radio label="1">新开</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="图标:" prop="icon">
          <el-input v-model="itemData.icon" maxlength="40"></el-input>
        </el-form-item>
      </div>
      <div class="form-item-box">
        <el-form-item label="外链url:" prop="iframeLink">
          <el-input
            v-model="itemData.iframeLink"
            maxlength="100"
            placeholder="请输入外链url"
          ></el-input>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { formatResourceTree, removeDataFromTree } from '@/utils/index'
export default {
  props: {
    resourceId: {
      type: String,
      default: ''
    },
    operType: {
      type: String,
      default: ''
    },
    type: String,
    resClassfiction: String,
    pName: String
  },
  setup(props) {
    const { dispatch } = useStore()
    const formRef = ref()
    const itemData = reactive({
      moduleName: '',
      moduleCode: '',
      moduleType: '',
      parentId: '',
      moduleUrl: '',
      rank: 1,
      showStatus: 'Y',
      icon: '',
      iframeFlag: '0',
      iframeOpenType: '0',
      iframeLink: ''
    })
    const radioOptions = ref([
      { name: '是', value: '1' },
      { name: '否', value: '0' }
    ])
    const radioShowOptions = ref([
      { name: '是', value: 'Y' },
      { name: '否', value: 'N' }
    ])
    const userFormRules = reactive({
      moduleName: [{ required: true, message: '请输入资源名称', trigger: 'blur' }],
      moduleCode: [{ required: true, message: '请输入资源代码', trigger: 'blur' }],
      moduleType: [{ required: true, message: '请选择资源类型', trigger: 'blur' }],
      rank: [{ required: true, message: '请输入顺序', trigger: 'blur' }],
      showStatus: [{ required: true, message: '请选择是否显示', trigger: 'blur' }],
      iframeFlag: [{ required: true, message: '请选择是否外链', trigger: 'blur' }],
      iframeOpenType: [{ required: true, message: '请选择是外链方式', trigger: 'blur' }]
    })
    watch(
      () => itemData.moduleType,
      newValue => {
        if (newValue === 'module_link') {
          userFormRules.moduleUrl = [
            { required: true, message: '请输入资源路由', trigger: 'blur' },
            {
              pattern: /^\/[a-zA-Z0-9]+$/,
              message: '请输入斜杠开头的字母数字组合，如：/baseplat',
              trigger: ['blur', 'change']
            }
          ]
        } else {
          userFormRules.moduleUrl = [
            {
              pattern: /^\/[a-zA-Z0-9]+$/,
              message: '请输入斜杠开头的字母数字组合，如：/baseplat',
              trigger: ['blur', 'change']
            }
          ]
        }
      }
    )
    watch(
      () => itemData.iframeOpenType,
      newValue => {
        if (newValue === '1') {
          userFormRules.iframeLink = [{ required: true, message: '请输入外链url', trigger: 'blur' }]
        } else {
          userFormRules.iframeLink = []
        }
      }
    )
    watch(
      () => props.resourceId,
      () => {
        getResourceData()
        if (props.operType === 'edit') {
          getDetailData()
        }
      }
    )
    const getDetailData = () => {
      dispatch('fetchGetSysModule', props.resourceId).then(data => {
        if (data.code === 200) {
          let node = data.data
          if (node) {
            Object.keys(node).forEach(d => {
              itemData[d] = node[d]
            })
          }
        }
      })
    }
    const moduleTypeOptions = ref([])
    const getMouleTypeDate = () => {
      dispatch('fetchGetResourceTypeModule').then(data => {
        if (data.code === 200) {
          moduleTypeOptions.value = data.data
        }
      })
    }
    const resourceOptions = ref([])
    const cascaderProps = reactive({
      label: 'title',
      value: 'id',
      children: 'children',
      checkStrictly: true,
      leaf: 'isLeaf'
    })
    const getResourceData = () => {
      dispatch('fetchGetModuleResourceAllTreeList').then(data => {
        if (data.code === 200) {
          resourceOptions.value = data.data
          formatResourceTree(resourceOptions.value)
          if (props.operType !== 'add' && props.resourceId) {
            removeDataFromTree(resourceOptions.value, { id: props.resourceId }, 'id', 'children')
          }
          if (props.operType === 'add') {
            itemData.parentId = props.resourceId || ''
          }
        }
      })
    }
    const validateHandle = () => {
      return new Promise((resolve, reject) => {
        formRef.value.validate(valid => {
          if (valid) {
            let parentId = itemData.parentId
            if (Array.isArray(itemData.parentId) && itemData.parentId.length > 0) {
              parentId = itemData.parentId[itemData.parentId.length - 1]
            }
            resolve({
              data: {
                ...itemData,
                parentId
              }
            })
          } else {
            reject()
          }
        })
      })
    }
    onMounted(async () => {
      getMouleTypeDate()
      getResourceData()
    })
    return {
      itemData,
      moduleTypeOptions,
      cascaderProps,
      resourceOptions,
      radioOptions,
      userFormRules,
      getDetailData,
      radioShowOptions,
      validateHandle,
      formRef
    }
  }
}
</script>
<style lang="scss" scoped>
.form-box {
  display: flex;
  flex: 1;
}
.form-box-dialog {
  display: block;
  margin-left: 0;
}
.form-item-box {
  display: flex;
  flex: 1;
}
.form-item-box + .form-item-box {
  margin-left: 48px;
}
.form-box-dialog .form-item-box + .form-item-box {
  margin-left: 0;
}
.form-item-box /deep/ .el-form-item {
  flex: 1;
}
.form-item-box .el-form-item + .el-form-item {
  margin-left: 48px;
}
.form-box-base {
  display: flex;
}
.form-box-base .form-box + .form-box {
  margin-left: 48px;
}
</style>